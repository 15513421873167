import {
  ArrowUpRightIcon,
  BanknotesIcon,
  BoltIcon,
  BookOpenIcon,
  ChartBarIcon,
  ChatBubbleBottomCenterTextIcon,
  HomeIcon,
  KeyIcon,
  UserCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/20/solid';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useSWRImmutable from 'swr/immutable';
import { Workspace } from '@flexpa/db';
import { classNames } from '../../lib/classNames';
import { fetcher } from '../../lib/fetcher';

export function SidebarNav() {
  const router = useRouter();
  const { data: workspace, isLoading } = useSWRImmutable<Workspace>(['/api/portal/workspaces'], fetcher);

  const disableTestMode = !workspace?.testModeEnabled ?? true;

  return (
    <nav className="flex w-56 flex-col gap-y-10 px-4 py-12 text-sm font-normal">
      {isLoading ? (
        <div className="w-full">Loading...</div>
      ) : workspace ? (
        <>
          <NavSection title="Overview">
            <Link href="/">
              <NavItem active={router.pathname === '/workspaces/[workspaceId]'}>
                <HomeIcon className="size-4" />
                Home
              </NavItem>
            </Link>
            <Link href="/settings">
              <NavItem active={router.pathname === '/settings'}>
                <UserCircleIcon className="size-4" />
                Settings
              </NavItem>
            </Link>
            <Link
              href={`/workspaces/${workspace.id}/patient-authorization-logs`}
              onClick={(event) => (disableTestMode ? event.preventDefault() : null)}
              title={disableTestMode ? 'Your workspace must be unlocked to view this page.' : ''}
            >
              <NavItem
                active={router.pathname === '/workspaces/[workspaceId]/patient-authorization-logs'}
                disabled={disableTestMode}
              >
                <ChartBarIcon className="size-4" />
                Patient Authorizations
              </NavItem>
            </Link>
            <Link
              href={`/workspaces/${workspace.id}/fhir-requests`}
              onClick={(event) => (disableTestMode ? event.preventDefault() : null)}
              title={disableTestMode ? 'Your workspace must be unlocked to view this page.' : ''}
            >
              <NavItem
                active={router.pathname === '/workspaces/[workspaceId]/fhir-requests'}
                disabled={disableTestMode}
              >
                <ChartBarIcon className="size-4" />
                FHIR requests
              </NavItem>
            </Link>
            <Link
              href={`/workspaces/${workspace.id}/api-requests`}
              onClick={(event) => (disableTestMode ? event.preventDefault() : null)}
              title={disableTestMode ? 'Your workspace must be unlocked to view this page.' : ''}
            >
              <NavItem active={router.pathname === '/workspaces/[workspaceId]/api-requests'} disabled={disableTestMode}>
                <ChartBarIcon className="size-4" />
                API requests
              </NavItem>
            </Link>
          </NavSection>

          <NavSection title="Business Settings">
            <Link
              href={`/workspaces/${workspace.id}/api-keys`}
              onClick={(event) => (disableTestMode ? event.preventDefault() : null)}
              title={disableTestMode ? 'Your workspace must be unlocked to view this page.' : ''}
            >
              <NavItem active={router.pathname === '/workspaces/[workspaceId]/api-keys'} disabled={disableTestMode}>
                <KeyIcon className="size-4" />
                <div className="flex items-center gap-[2px]">API Keys</div>
              </NavItem>
            </Link>
            <Link href={`/workspaces/${workspace.id}/live-mode-access-forms`}>
              <NavItem
                active={
                  router.pathname === '/workspaces/[workspaceId]/live-mode-access-forms' ||
                  router.pathname === '/workspaces/[workspaceId]/live-mode-access-forms/[liveModeAccessFormId]'
                }
              >
                <BoltIcon className="size-4" />
                <div className="flex items-center gap-[2px]">Live mode access</div>
              </NavItem>
            </Link>
            <Link href={`/workspaces/${workspace.id}/billing`}>
              <NavItem>
                <BanknotesIcon className="size-4" />
                <div className="flex items-center gap-[2px]">
                  Payments & billing
                  <ArrowUpRightIcon className="size-3" />
                </div>
              </NavItem>
            </Link>
            <Link href={`/workspaces/${workspace.id}/team`}>
              <NavItem active={router.pathname === '/workspaces/[workspaceId]/team'}>
                <UserGroupIcon className="size-4" />
                <div className="flex items-center gap-[2px]">Team details</div>
              </NavItem>
            </Link>
          </NavSection>
          <NavSection title="Support">
            <a href="https://www.flexpa.com/docs" target="_blank" rel="noreferrer">
              <NavItem>
                <BookOpenIcon className="size-4" />
                <div className="flex items-center gap-[2px]">
                  Documentation
                  <ArrowUpRightIcon className="size-3" />
                </div>
              </NavItem>
            </a>
            <a href="mailto:support@flexpa.com">
              <NavItem>
                <ChatBubbleBottomCenterTextIcon className="size-4" />
                <div className="flex items-center gap-[2px]">
                  Support
                  <ArrowUpRightIcon className="size-3" />
                </div>
              </NavItem>
            </a>
          </NavSection>
        </>
      ) : (
        <div className="w-full">Error: Workspace not found</div>
      )}
    </nav>
  );
}

function NavItem({ active, children, disabled }: { children: React.ReactNode; active?: boolean; disabled?: boolean }) {
  return (
    <li
      className={classNames(
        'flex items-center gap-2 p-2',
        active
          ? 'border-l-2 border-l-flexpa-green-tertiary-100 bg-flexpa-green-tertiary-25 font-semibold text-flexpa-green-secondary-100'
          : '',
        disabled ? 'cursor-not-allowed opacity-50' : '',
      )}
    >
      {children}
    </li>
  );
}

function NavSection({ title, children }: { title: React.ReactNode; children: React.ReactNode }) {
  return (
    <section>
      <h3 className="p-2 font-semibold tracking-wide">{title}</h3>
      <ul>{children}</ul>
    </section>
  );
}
